import axios, { AxiosInstance } from "axios";
import { notification } from "antd";
import { config } from "./config";
import { getFromLocal, removeFromLocal } from "./cache";

const BASE_URL: string = config.REACT_APP_BASE_URL || "";

export class HttpService {
  private _axios: AxiosInstance;

  constructor(baseURL: string, headers = {}) {
    this._axios = axios.create({
      baseURL,
      headers,
    });
  }

  get(endpoint: string, params = {}, headers = {}): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    return this._axios
      .get(endpoint, { params, headers: authHeaders })
      .catch(this.errorHandling);
  }

  post(endpoint: string, body: any, headers = {}): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    return this._axios
      .post(endpoint, body, { headers: authHeaders })
      .catch(this.errorHandling);
  }

  put(endpoint: string, data: any, headers = {}) {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    return this._axios({
      method: "put",
      url: endpoint,
      data,
      headers: authHeaders,
    }).catch(this.errorHandling);
  }

  patch(endpoint: string, data: any, headers = {}): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    return this._axios({
      method: "patch",
      url: endpoint,
      data,
      headers: authHeaders,
    }).catch(this.errorHandling);
  }

  delete(endpoint: string, data: any, headers = {}): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    return this._axios({
      method: "delete",
      url: endpoint,
      data,
      headers: authHeaders,
    }).catch(this.errorHandling);
  }

  errorHandling(err: any): any {
    if (
      err?.response?.status === 403 ||
      err?.response?.data?.detail === "Token is expired"
    ) {
      removeFromLocal("token");
      notification.info({
        message: "Session expired",
        description: "Your session has been expired please login again",
      });
      setTimeout(() => {
        window.location.href = "/login";
      }, 2000);
    }
    throw err;
  }


}

const JSON_HEADERS: any = {
  "Content-Type": "application/json",
  "X-APP-ID": config.REACT_APP_X_APP_ID,
  "X-API-KEY": config.REACT_APP_X_API_KEY,
};

// const MULTIPART_HEADERS: any = {
//   "Content-Type":
//     "multipart/form-data; boundary=<calculated when request is sent>",
//   Authorization: `Bearer ${getFromLocal("token")}`,
// };


export const http = new HttpService(BASE_URL, JSON_HEADERS);




const EXISTING_USER_BASE_URL: string = config.REACT_APP_EXISTING_USER_URL || "";

const EXISTING_USER_JSON_HEADERS: any = {
  "Content-Type": "application/json",
  
};

export const httpExistingUser = new HttpService(EXISTING_USER_BASE_URL,EXISTING_USER_JSON_HEADERS)