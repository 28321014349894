export const formatPhoneNumber = (phoneNumber: string): string => {
  // Check if the phone number is valid and has a length of 12 characters
//   if (phoneNumber.length === 12 && /^\d+$/.test(phoneNumber)) {
    const formattedPhoneNumber =
      phoneNumber.substring(0, 4) + "****" + phoneNumber.substring(8);
    return formattedPhoneNumber;
//   } else {
    // return "Invalid phone number";
//   }
};
