import {
  Link,
  // Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Registration from "../pages/Registration";
import OTPVerification from "../pages/Registration/OTPVerification/OTP";
import Profile from "../pages/Registration/Profile/Profile";
import DeliveryLocation from "../pages/Registration/DeliveryLocation/DeliveryLocation";
import EditProfile from "../pages/Registration/EditProfile/EditProfile";
import Thankyou from "../pages/Registration/Thankyou/Thankyou";
import React from "react";

const WrongRoute: React.FC = () => (
  <div>
    Nothing found please <Link to={"/"}>Click Here</Link>
  </div>
);
const RootRoute = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Registration />} />
        <Route path="/phone-verification" element={<OTPVerification />} />
        <Route path="/profile-update" element={<Profile />} />
        <Route path="/delivery-location" element={<DeliveryLocation />} />
        <Route path="/profile-edit" element={<EditProfile />} />
        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="*" element={<WrongRoute />} />
      </Routes>
    </Router>
  );
};

export default RootRoute;
