const {
  REACT_APP_BASE_URL,
  REACT_APP_X_APP_ID,
  REACT_APP_X_API_KEY,
  REACT_APP_OTP_API_CODE,
  REACT_APP_COMPANY_ID,
  REACT_APP_PASSWORD,
  REACT_APP_API_VERSION,
  REACT_APP_EXISTING_USER_URL
} = process.env;

interface Config {
  REACT_APP_BASE_URL: string | undefined;
  REACT_APP_X_APP_ID: string | undefined;
  REACT_APP_X_API_KEY: string | undefined;
  REACT_APP_OTP_API_CODE: string | undefined;
  REACT_APP_COMPANY_ID: string | undefined;
  REACT_APP_PASSWORD: string | undefined;
  REACT_APP_API_VERSION: string | undefined;
  REACT_APP_EXISTING_USER_URL: string | undefined;
}

const config: Config = {
  REACT_APP_BASE_URL,
  REACT_APP_X_API_KEY,
  REACT_APP_X_APP_ID,
  REACT_APP_COMPANY_ID,
  REACT_APP_OTP_API_CODE,
  REACT_APP_PASSWORD,
  REACT_APP_API_VERSION,
  REACT_APP_EXISTING_USER_URL
};

export { config };
