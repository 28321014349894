import {
  Anchor,
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Spin,
  Typography,
} from "antd";
import CountryDropdown from "../../components/shared/CountryDropdown";
import SVGToComponent from "../../components/shared/icons/icons";
import CallIcon from "../../assets/images/Call.svg";
import SmileyIcon from "../../assets/images/smiley-icon.svg";

import "./Registration.scss";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { dispatchOTPSentAction } from "../../redux/appSlice";
import { registration } from "../../services/app.service";
import { config } from "../../utils/config";
const { Link } = Anchor;

const Registration: React.FC<any> = () => {
  const [dialingCode, setDialingCode] = useState("");
  const [loader, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (values: any) => {
    //const phone = `${values.code}${values.phoneNumber}`;
    const phone = `+254${values.phoneNumber}`;
    try {
      setLoading(true);
      await registration({
        phone,
        code: config.REACT_APP_OTP_API_CODE,
        company_id: Number(config.REACT_APP_COMPANY_ID),
      });
      dispatch(
        dispatchOTPSentAction({
          phone,
        })
      );
      setLoading(false);
      navigate("/phone-verification");
    } catch (ex: any) {
      setLoading(false);
    }
    dispatch(
      dispatchOTPSentAction({
        phone,
      })
    );
    navigate("/phone-verification");
  };
  return (
    <Spin spinning={loader}>
      <div className="copia-registration-main-container">
        <section className="copia-registration-header-content">
          <Row justify={"center"} align={"middle"}>
            <div className="copia-registration-container">
              <Col span={24}>
                <Row justify={"center"} align={"middle"}>
                  <div className="copia-logo" />
                </Row>
              </Col>
              <Col span={24}>
                <div className="copia-banner-container">
                  <Row justify={"start"} gutter={40}>
                    <Col span={2}>
                      <div className="copia-banner-icon" />
                    </Col>
                    <Col span={20}>
                      <Typography.Paragraph className="copia-banner-text">
                        Get 100MB data free once you complete this process
                      </Typography.Paragraph>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={24}>
                <Typography.Title level={3}>Register/Sign in</Typography.Title>
              </Col>
              <Col span={24}>
                <Form onFinish={onSubmit}>
                  <Form.Item name={"code"}>
                    <CountryDropdown onChange={setDialingCode} />
                  </Form.Item>
                  <Form.Item 
                    name={"phoneNumber"}
                    rules={[{ required: true }]}                 
                  >
                    <InputNumber
                      className="copia-phone-input"
                      prefix={<SVGToComponent icon={CallIcon} />}
                      placeholder="Enter the Phone Number"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="copia-registration-submit-button"
                      htmlType="submit"
                    >
                      Continue
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </div>
          </Row>
        </section>
        <section className="copia-registration-footer-content">
          <div className="copia-registration-privacy-policy">
            <Row justify={"start"} align={"middle"}>
              <Col span={2}>
                <img src={SmileyIcon} alt="" />
              </Col>
              <Col span={20}>
                <div className="copia-registration-privacy-policy-text">
                  This website does not consume your data
                </div>
              </Col>
            </Row>
          </div>
          <div className="copia-privacy-policy-links">
            <span>
              <Link href="https://google.com" title="Privacy policy" />
            </span>
            <Divider type="vertical" />
            <span>
              <Link href="https://google.com" title="Terms & Conditions" />
            </span>
          </div>
          <div style={{ fontSize: 14 }}>Copyright @ 2023 Copia Global</div>
        </section>
      </div>
    </Spin>
  );
};
export default Registration;
