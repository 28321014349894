import { useEffect, useState } from "react";
import { Spin, Typography } from "antd";

import "./OTP.scss";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { formatPhoneNumber } from "../../../utils/helpers";
import { verifyOtp } from "../../../services/app.service";
import { config } from "../../../utils/config";

const OTPVerification: React.FC = () => {
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const state = useSelector((state: any) => state.app);

  const navigate = useNavigate();

  const onOTPSubmit = async () => {
    try {
      setLoader(true);
      await verifyOtp({
        phone: state.phone,
        otp: Number(otp),
        app_version: parseInt(config.REACT_APP_API_VERSION as string),
        company_id: Number(config.REACT_APP_COMPANY_ID),
      });
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
    navigate("/profile-update");
  };
  useEffect(() => {
    if (otp.length === 6) {
      onOTPSubmit();
    }
  }, [otp, navigate]);
  return (
    <Spin spinning={loader}>
      <div className="copia-otp-page">
        <div className="copia-logo" />
        <div className="copia-otp-heading">
          <Typography.Title level={3}>Verify phone number</Typography.Title>
          <Typography.Paragraph>
            Please wait for an SMS from Copia to{" "}
            {formatPhoneNumber(state.phone)} with your verification code
          </Typography.Paragraph>
        </div>
        <div className="copia-otp-container">
          <OTPInput
            containerStyle={{
              justifyContent: "space-evenly˝",
            }}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <Typography.Paragraph className="copia-otp-resent-button">
          Resend the Code
        </Typography.Paragraph>
      </div>
    </Spin>
  );
};

export default OTPVerification;
