/* eslint-disable */
// Production Details
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
//Production
const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:8832f113-d1b9-40c1-98e2-ab671ccb5124",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_rFFfxd996",
    "aws_user_pools_web_client_id": "7mdfpgoqnr2e1j6od89kjh0ajb",
    "oauth": {},
    "aws_cognito_username_attributes": [
    "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
    "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
    ],
    "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
    ],
    "aws_user_files_s3_bucket": "copiaidentityed99596fd8ef4bb29549148a4895b55e162504-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
   };
   
   
   export default awsmobile;