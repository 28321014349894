import {
  Anchor,
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Spin,
  Typography,
} from "antd";
import CountryDropdown from "../../../components/shared/CountryDropdown";
import SVGToComponent from "../../../components/shared/icons/icons";
import CallIcon from "../../../assets/images/Call.svg";
import SmileyIcon from "../../../assets/images/smiley-icon.svg";

import "../Thankyou/Thankyou.scss";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { dispatchOTPSentAction } from "../../../redux/appSlice";
import { registration } from "../../../services/app.service";
import { config } from "../../../utils/config";
const { Link } = Anchor;

const Thankyou: React.FC<any> = () => {
  const [dialingCode, setDialingCode] = useState("");
  const [loader, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (values: any) => {
    const phone = `${values.code}${values.phoneNumber}`;
    try {
      setLoading(true);
      await registration({
        phone,
        code: config.REACT_APP_OTP_API_CODE,
        company_id: Number(config.REACT_APP_COMPANY_ID),
      });
      dispatch(
        dispatchOTPSentAction({
          phone,
        })
      );
      setLoading(false);
      navigate("/phone-verification");
    } catch (ex: any) {
      setLoading(false);
    }
    dispatch(
      dispatchOTPSentAction({
        phone,
      })
    );
    navigate("/phone-verification");
  };
  return (
    <Spin spinning={loader}>
      <div className="thanks-main-class">
        <div className="thanks-nav-class">
          <h3>
            Congratulations <br /> winnies!
          </h3>
          <div className="copia-banner-icon" />
        </div>
        <span className="thanks-subtitle">A special treat awaits</span>
        <img alt="Thank you" src="/assets/images/get-copia-app.png" />
        <p>
          Your 100MB of free data will be in your phone before long. Keep an eye
          on your inbox for a confirmation SMS and tap on the link below to
          download Copia App for a super charged shopping experience with
          Special App-Only Offers and no data costs.
        </p>
        <div className="app-class">
          <h4>Download Copia App</h4>
          <img alt="Thank you" src="/assets/images/playstore.png" />
        </div>
        <div className="available-deals">
          <span>Checkout hot deals available <br/> only on Copia app</span>
          <button>Copia app deals</button>
        </div>

        <footer>
          <div className="footer-tag">
          <a href="/"className="privacy-tag">Privacy policy</a> 
          <a href="/" className="term-tag">Terms & Conditions</a>
          </div>
         <span>Copyright @ 2023 Copia Global</span>
        </footer>
      </div>
    </Spin>
  );
};
export default Thankyou;
