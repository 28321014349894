// src/slices/appSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Define your initial state here
  otpSent: false,
  phone: "",
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    dispatchOTPSentAction: (state, action) => {
      state.otpSent = true;
      state.phone = action.payload.phone;
    },
    resetOTPAction: (state, action) => {
      state.otpSent = false;
      state.phone = "";
    },
    // Define your reducer actions here
  },
});

export const { dispatchOTPSentAction, resetOTPAction } = appSlice.actions;
export default appSlice.reducer;
