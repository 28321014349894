import { Auth } from "aws-amplify";
import { http, httpExistingUser } from "../utils/client";
import { EXISTING_CUSTOMER_URL, OTP_VERIFICATION_URL, REGISTRATION_URL } from "./app.endpoints";
import {
  ICognitoSignupPayload,
  IExistingUserPayload,
  IOTPVerificationPayload,
  IRegistrationPayload,
  IRegistrationResponse,

} from "./app.interface";
import { config } from "../utils/config";

export const registration = async (
  body: IRegistrationPayload
): Promise<IRegistrationResponse> => {
  try {
    const response = await http.post(REGISTRATION_URL, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyOtp = async (
  body: IOTPVerificationPayload
): Promise<any> => {
  try {
    const response = await http.post(OTP_VERIFICATION_URL, body);
    return response;
  } catch (ex) {
    throw ex;
  }
};

export const cognitoSignup = async (
  body: ICognitoSignupPayload
): Promise<any> => {
  try {
    const response = await Auth.signUp({
      ...body,
      password: config.REACT_APP_PASSWORD as string,
    });
    return response;
  } catch (ex) {
    throw ex;
  }
};


export const fetchExistingUser = async (
  body: IExistingUserPayload
): Promise<any> => {
  try {
    const response = await httpExistingUser.post(EXISTING_CUSTOMER_URL, body);
    return response;
  } catch (ex) {
    throw ex;
  }
};

