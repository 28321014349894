import {
  Col,
  Row,
  Typography,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Spin,
} from "antd";
import "./EditProfile.scss";
import { useRef, useState } from "react";
import type { FormInstance } from "antd/es/form";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { cognitoSignup } from "../../../services/app.service";

const { Option } = Select;

const formStyles: React.CSSProperties = {
  borderRadius: 8,
  border: "1px solid #A0A0A0",
  background: "#F6F6F6",
  textAlign: "left",
};
const inputStyles: React.CSSProperties = {
  height: 40,
};
const EditProfile: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<FormInstance>(null);
  const navigate = useNavigate();
  const state = useSelector((state: any) => state.app);

  const onGenderChange = (value: string) => {
    switch (value) {
      case "male":
        formRef.current?.setFieldsValue({ note: "Hi, man!" });
        break;
      case "female":
        formRef.current?.setFieldsValue({ note: "Hi, lady!" });
        break;
      case "other":
        formRef.current?.setFieldsValue({ note: "Hi there!" });
        break;
      default:
        break;
    }
  };
  const onSubmit = async (values: any) => {
    
    setLoading(true);
    try {
      const response = await cognitoSignup({
        username: values.phoneNumber,
        attributes: {
          email: values.email || "",
          phone_number: values.phoneNumber,
          given_name: values.firstName,
          family_name: values.lastName,
          gender: values.gender,
          birthdate: values.birthdate,
          address: ""
        },
      });
      setLoading(false);
    } catch (ex) {
      setLoading(false);
    }
    navigate("/thank-you")
  };
  return (
    <Spin spinning={loading}>
      <div className="copia-profile-update-container">
        <Row
          justify={"space-between"}
          align={"middle"}
          style={{ marginBottom: 10 }}
        >
          <Col span={20}>
            <Typography.Title
              level={4}
              style={{ textAlign: "left", margin: 0 }}
            >
              Edit your profile
            </Typography.Title>
          </Col>
          <Col span={4}>
            <div className="copia-profile-update-side-logo" />
          </Col>
        </Row>
        <Row justify={"start"}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Typography.Paragraph>
              Your profile information
            </Typography.Paragraph>
          </Col>
          <Col span={24}>
            <Form
              layout="vertical"
              onFinish={onSubmit}
              initialValues={{ ["phoneNumber"]: state.phone }}
            >
              <Form.Item name={"phoneNumber"} style={formStyles}>
                <Input
                  type="text"
                  value={state.phone}
                  placeholder="Enter your phone number"
                  style={inputStyles}
                  disabled
                />
              </Form.Item>
              <Form.Item name={"firstName"} style={formStyles}>
                <Input
                  type="text"
                  placeholder="First Name"
                  style={inputStyles}
                />
              </Form.Item>
              <Form.Item name={"lastName"} style={formStyles}>
                <Input
                  type="text"
                  placeholder="Last Name"
                  style={inputStyles}
                />
              </Form.Item>
              <Form.Item
                name="gender"
                rules={[{ required: true }]}
                style={{ textAlign: "left", ...formStyles }}
              >
                <Select
                  placeholder="Select Gender"
                  onChange={onGenderChange}
                  allowClear
                  style={inputStyles}
                >
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
              <Form.Item name={"alternativePhoneNumber"} style={formStyles}>
                <Input
                  type="text"
                  placeholder="Alternative phone number"
                  style={inputStyles}
                />
              </Form.Item>
              <Form.Item
                label="Select Date of birth"
                style={{ textAlign: "left", marginBottom: 30 }}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="Got an email address? (optional)"
                name={"email"}
                style={{ marginBottom: 50 }}
              >
                <Input
                  type="email"
                  placeholder="Your email address"
                  style={inputStyles}
                />
              </Form.Item>
              <Button className="copia-profile-submit-button" htmlType="submit">
                Next
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default EditProfile;
